// src/App.js
import React, { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import "./App.css";
import EmailList from "./components/EmailList";
import EmailDetail from "./components/EmailDetail";
import PasscodeInput from "./components/PasscodeInput";
const AUTO_RELOAD_TIME = 1; // in minutes
const passcode = "Since2023!"; // Hardcoded passcode

const emailsData = [
  {
    id: 1,
    subject: "Welcome!",
    from: "admin@example.com",
    body: "Welcome to our service!",
    date: "2024-06-11T08:30:00Z",
  },
  {
    id: 2,
    subject: "React Update",
    from: "react@example.com",
    body: "React version 17 released.",
    date: "2024-06-10T09:45:00Z",
  },
  {
    id: 3,
    subject: "Meeting Reminder",
    from: "boss@example.com",
    body: "Don't forget about the meeting tomorrow at 10 AM.",
    date: "2024-06-09T10:15:00Z",
  },
];

function App() {
  const [selectedEmailId, setSelectedEmailId] = useState(null);
  const [emails, setEmails] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [totalEmails, setTotalEmails] = useState(null);
  const [autoReload, setAutoReload] = useState(true)

  const handleEmailClick = (id) => {
    setSelectedEmailId(id);
  };

  const handlePasscodeSubmit = (inputPasscode) => {
    if (inputPasscode === passcode) {
      setIsLoggedIn(true);
      localStorage.setItem("isLoggedIn", inputPasscode);
    } else {
      alert("Incorrect passcode");
    }
  };

  useEffect(() => {
    const select = emails.find((email) => email._id === selectedEmailId);
    setSelectedEmail(select);
  }, [selectedEmailId, emails]);

  const fetchEmails = useCallback(async () => {
    if(autoReload){
      try {
        const response = await fetch(
          // "https://api.nomo-earnings.com/emails/get-emails"
          "https://api.nomo-earnings.com/emails/get-emails"
        );
        const data = await response.json();
        setTotalEmails(data.totalEmails);
        const newEmails = data?.data || [];
        setAutoReload(false)
        setEmails((prevEmails) => {
          if (!_.isEqual(prevEmails, newEmails)) {
            return newEmails;
          }
          console.log("No new data found");
          return prevEmails;
        });
      } catch (error) {
        console.error("Error fetching emails:", error);
        setAutoReload(false)
      }
    };
    
  }, []);

  useEffect(() => {
    fetchEmails();
    // const intervalId = setInterval(fetchEmails, AUTO_RELOAD_TIME * 60000);
    // return () => clearInterval(intervalId);
  }, [fetchEmails]);

  useEffect(() => {
    const loggedInStatus = localStorage.getItem("isLoggedIn");
    if (loggedInStatus === passcode) {
      setIsLoggedIn(true);
    }
  }, []);

  if (!isLoggedIn) {
    return <PasscodeInput onPasscodeSubmit={handlePasscodeSubmit} />;
  }

  return (
    <div className="app">
      <div className="email-list-container">
        <EmailList
          emails={emails}
          onEmailClick={handleEmailClick}
          setEmails={setEmails}
          totalEmails={totalEmails}
          setAutoReload={setAutoReload}
          reloading={autoReload}
        />
      </div>
      <div className="email-detail-container">
        <EmailDetail email={selectedEmail} />
      </div>
    </div>
  );
}

export default App;
