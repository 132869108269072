// src/components/PasscodeInput.js
import React, { useState } from 'react';


const PasscodeInput = ({ onPasscodeSubmit }) => {
  const [passcode, setPasscode] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    onPasscodeSubmit(passcode);
  };

  return (
    <div className="passcode-container">
      <form onSubmit={handleSubmit}>
        <label>
          <input
            type="password"
            value={passcode}
            onChange={(e) => setPasscode(e.target.value)}
            placeholder="Enter Passcode"
          />
        </label>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default PasscodeInput;
