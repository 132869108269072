// src/components/EmailDetail.js
import React, { useRef } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactToPrint from 'react-to-print';




const parseContent = (content) => {
  const sections = content?.split('\n\n').filter(section => section.trim());
  return sections?.map(section => {
    if (section.startsWith('|')) {
      const lines = section?.split('\n');
      const headers = lines[0]?.split('|').map(header => header.trim()).filter(header => header);
      const data = lines.slice(2).map(line => line?.split('|').map(cell => cell.trim()).filter(cell => cell));
      return { type: 'table', headers, data };
    } else if (section.startsWith('### ')) {
      return { type: 'header', text: section.replace('### ', '') };
    } else {
      return { type: 'text', text: section };
    }
  });
};

function removeHtmlTags(content) {
  const tagPattern = /<\/?[^>]+>/gi;
  return content.replace(tagPattern, '');
}





function EmailDetail({ email }) {
  let sections = parseContent(email?.content)
  const componentRef = useRef();

  function getFirstHashString(input) {
    // Split the input string into an array of lines
    const lines = input.split('\n');

    // Loop through each line
    for (let line of lines) {
      // Check if the line starts with a hash followed by a space
      if (line.startsWith('# ')) {
        // Return the line without the hash and the following space
        return line.slice(2).trim();
      }
    }

    // Return null if no line starts with a hash
    return "No Title Found";
  }


  
 

  return (
    <div className="email-detail print-container" ref={componentRef}>
      {email ? (
        <>
          <div className="email-header">
            <h2>{getFirstHashString(email?.original)}</h2>
            <div>
              <CopyToClipboard text={removeHtmlTags(email?.original)}
                onCopy={() => toast.success("Copied!", {
                  position: "bottom-left",
                  autoClose: 2000
                })}>
                <button className="tooltip-button">
                  <div className="fa fa-copy"></div>
                  <span className="tooltip-text">Copy</span>
                </button>
              </CopyToClipboard>


              {/* <button className="copy-button" onClick={handleShareByEmail}>
              <div class="fa fa-share"></div>
            </button> */}
              <ReactToPrint
                trigger={() => <button className="tooltip-button">

                  <div className="fa fa-print"></div>
                  <span className="tooltip-text">Print</span>
                </button>}
                content={() => componentRef.current}
              />


            </div>


          </div>

          <div>

          </div>
          <div dangerouslySetInnerHTML={{ __html: `<pre>${email?.content}</pre>` }} />
        </>
      ) : (
        <p style={{margin:'auto', textAlign:'center'}}>Select an email to view details</p>
      )}
      <ToastContainer />

    </div>
  );
}

export default EmailDetail;
